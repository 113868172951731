import React from "react"
import styled from "styled-components";
import Layout from "../layout";
import Button from "../components/button";
import TextBox from "../components/text-box";
const Item = styled.div``;
const ResumePage = () => {
  return (
    <Layout>
      <Item />
      <TextBox>Some Text</TextBox>
      <Button>Back Home</Button>
    </Layout>
  );
}
export default ResumePage;